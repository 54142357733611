import React, { useState, useEffect } from 'react';
import { getSessionData } from './utils'; 

const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL; 
const user = getSessionData('user'); 
const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [total_campaigns, setTotalCampaigns] = useState(0);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    // Fetch the email campaign data when the component mounts
    const fetchCampaigns = async () => {
      const endpoint = `list-email-campaigns?user_id=${user.id}`; 
      try {
        const response = await fetch(`${apiUrl}/api/${endpoint}`);
        if (!response.ok) {
          throw new Error('Network response was not ok'); 
        }
        const data = await response.json();
        setCampaigns(data); 
        setTotalCampaigns(data.length);
      } catch (error) {
        console.error('Error fetching email campaigns:', error);
      } finally {
        setLoading(false); 
      }
    };

    if (user && user.id) { 
      fetchCampaigns();
    } else {
      setLoading(false); // Turn off loading indicator
    }
  }, []); 

  const handleRunCampaign = async (id) => {
    setLoading(true);
    const endpoint = 'run_campaign';
    const response = await fetch(`${apiUrl}/api/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    alert('Campaign run successfully');
    setLoading(false);
    const data1 = await response.json();
    window.location.reload();
    return data1;
  };

  const handleDeleteCampaign = async (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this email account?");
    if (isConfirmed) {
      try {
        setLoading(true);
        const endpoint = 'delete_campaign';
        const response = await fetch(`${apiUrl}/api/${endpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        alert('Email account deleted successfully');
        setLoading(false);
        const data1 = await response.json();
        window.location.reload();
        return data1;
      } catch (error) {
        setLoading(true);
        alert("Failed to delete the email account. Please try again.");
      }
    } else {
      console.log("Campaign deletion canceled.");
    }
  };

  return (
    <div className="campaign_list test">
      <div className="tophead_mainsec">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="title_mainsec">
              <h2>Accounts ({total_campaigns})</h2>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="btn_mainsec">
              <a class="btn_style" href={`${homeUrl}/add-email`}>+ Add New</a>
            </div>
          </div>
        </div>
      </div>
      
      <div className="email_listing_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="card-body">
              <div className="table_mainsec">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <label className="custom_checkbox">
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                        </th>
                        <th>Name</th>
                        <th>Email Address</th>
                        <th>Email Provider</th>
                        <th>Daily Limit</th>
                        <th>Warmup Enabled</th>
                        <th>Reputation</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    
                    <tbody>
                    {campaigns.length > 0 ? (
                      campaigns.map((campaign, index) => (
                        <tr key={index}>
                          <td>
                            <label className="custom_checkbox">
                              <input type="checkbox" />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                          <td>{campaign.first_name} {campaign.last_name}</td>
                          <td><a href={`${homeUrl}/campaign-emails/${campaign.id}`}>{campaign.smtp_username}</a></td>
                          <td>SMTP</td>
                          <td>{campaign.total_emails} / {campaign.daily_limit}</td>
                          <td>{campaign.warmup_enabled === 'TRUE' ? 'Yes' : 'No'}</td>
                          <td>
                            <div className="budge green">{campaign.warmup_emails} %</div>
                          </td>
                          <td>
                            <div className="action_btn">
                              <button className="refresh_btn" onClick={(e) => handleRunCampaign(campaign.id)}>
                                <i class="fas fa-sync-alt"></i>
                              </button>
                              {/* <a className="edit_btn" href="#"><i className="far fa-edit"></i></a> */}
                              <button className="remove_btn" onClick={(e) => handleDeleteCampaign(campaign.id)}>
                                <i className="far fa-trash-alt"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8">No campaigns found</td>
                        </tr>
                    )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? <div className="loader_mainsec">
          <div className="spinner"></div>
        </div> : <div></div>}
    </div>   
  );
};

export default Campaigns;

import React, {  } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet,Navigate } from 'react-router-dom';
import './App.css';
// import './assets/css/layout.css'; 
// import './assets/css/bootstrap.min.css'; 
// import '@fortawesome/fontawesome-free/css/all.min.css';
import ScrollToTop from './components/ScrollToTop';
import { Link } from 'react-router-dom';
import Home from './components/Home';
import BulkUpload from './components/BulkUpload';
import Login from './components/Login';
import Register from './components/Register';
import Forgotpassword from './components/Forgotpassword';
import Searchdomain from './components/Searchdomain';
import Emailchecker from './components/Emailchecker';
import Layout from './components/Layout'; // Import the Layout component
import Dashboard from './components/Dashboard';
import Campaigns from './components/Campaigns';
import CampaignEmails from './components/CampaignEmails';
import Analytics from './components/Analytics';
import AddEmail  from './components/AddEmail';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import { saveSessionData, getSessionData } from './components/utils';
const homeUrl = process.env.REACT_APP_HOME_URL;


const App = () => {
  const user = getSessionData('user');
  const logout = () => {
    saveSessionData('user', null);
    window.location.href = homeUrl+'/login';
  };
  return (
    <Router>
      <ScrollToTop />
       {!user && (
      <div className="header_mainsec fixed_sec">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-brand" href={`${homeUrl}`}><img src={`${homeUrl}/assets/img/main-logo.png`} /></a>
           {user ? (<div className="right_box">
              <div className="dropdown profile_boxsec">
                <a className="dropdown_toggle" href="#">
                  <span className="profile_imgbox">
                    <img src={`${homeUrl}/assets/img/profile-default-img.png`} />
                  </span>
                
                  <span className="profile_txt">
                    {user.name} <i className="fas fa-chevron-down"></i>
                  </span>
                </a>
                
                <div className="dropdown-menu profile-dropdown">                
                  <a href="#" className="dropdown-item notify-item" onClick={logout}>
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Logout </span>
                  </a>
                </div>
              </div>
            </div> ) : (
            <div className="right_box">
              <div className="btn_mainsec">
                <a className="btn_style" href={`${homeUrl}/login`}>Get Access</a>
              </div>
            </div>   )}
          </div>
        </nav>
      </div>
   )}
       <Routes>
        {/* Protected Routes for logged-in users */}
        {user ? (
          <Route element={<Layout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/campaigns" element={<Campaigns />} />
            <Route path="/campaign-emails/:campaignId" element={<CampaignEmails />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/add-email" element={<AddEmail />} />
            <Route path="/emailchecker" element={<Emailchecker />} />
            <Route path="/search-domain" element={<Searchdomain />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
            <Route path="/" element={<Navigate to="/dashboard" />} />
          </Route>
        ) : (
          // Unprotected Routes for logged-out users
          <>
           <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          </>
        )}
        <Route exact path="/bulk-upload" element={<BulkUpload />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Register />} />
        <Route exact path="/forgot-password" element={<Forgotpassword />} />
        <Route exact path="/search-domain" element={<Searchdomain />} />
        <Route exact path="/emailchecker" element={<Emailchecker />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Navigate to={user ? '/' : '/login'} />} />
      </Routes>
    {!user && (
    <div class="footer_mainsec">
		<div class="container">
			<div class="row">
				<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
					<div class="logo_box">
						 <a href={`${homeUrl}`}><img src={`${homeUrl}/assets/img/main-logo.png`} /></a>
					</div>
				</div>
				
				<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
					<div class="footer_menu">
						<ul>
							<li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
							 <li><Link to="/privacy-policy">Privacy Policy</Link></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
    )}
    </Router>
  );
};

export default App;

import React, { useState, useRef } from 'react';
import Papa from 'papaparse';
import { getSessionData } from './utils';
//const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL;

const AddEmail = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [cName, setCName] = useState('Untitled Campaign');
  const [subject, setSubject] = useState('');
  const [cMsg, setCMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [first_name, setFirstname] = useState(''); 
  const [last_name, setLastname] = useState(''); 
  const [smtp_username, setSMTPusername] = useState(''); 
  const [smtp_password, setSMTPpassword] = useState(''); 
  const [smtp_host, setSMTPhost] = useState(''); 
  const [smtp_port, setSMTPport] = useState(''); 
  const [daily_limit, setDailylimit] = useState('');
  const [warmup_limit, setWarmuplimit] = useState('');
  const [warmup_enabled, setWarmupenabled] = useState('FALSE');
  const fileInputRef = useRef(null);

  const handleCampaignNameChange = async (e) => {
    const cname = e.target.value;
    setCName(cname);
    
    // if (cname.trim() === '') {
    //   setNameValid(false);
    //   setNameError("Campaign name can't be empty.");
    //   return;
    // }

    // try {
    //   const response = await fetch(`${apiUrl}/api/check-campaign-name`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Accept': 'application/json',
    //     },
    //     body: JSON.stringify({ campaign_name: cname }),
    //   });

    //   const result = await response.json();

    //   if (response.ok && result.isUnique) {
    //     setNameValid(true);
    //     setNameError('');
    //   } else {
    //     setNameValid(false);
    //     setNameError('Campaign name already exists. Please choose another.');
    //   }
    // } catch (error) {
    //   console.error("Error checking campaign name:", error);
    //   setNameValid(false);
    //   setNameError('Error checking campaign name. Please try again.');
    // }
  };

  const handleWarmupenabled = async (e) => {
    const cname = e.target.value;
    setWarmupenabled(cname);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!nameValid) {
    //   alert("Please choose a unique campaign name.");
    //   return;
    // }

    if (!file) {
      alert("Please upload a CSV file.");
      return;
    }

    setLoading(true);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const data = results.data;
        const userId = getSessionData('user')?.id;

        if (!userId) {
          alert('User ID is missing');
          setLoading(false);
          return;
        }

        const endpoint = 'email-campaign';
        try {
          const response = await fetch(`${apiUrl}/api/${endpoint}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
            body: JSON.stringify({ data, user_id: userId, cName: cName, subject: subject, cMsg: cMsg }),
          });

          if (response.ok) {
            alert("CSV data uploaded successfully!");
            setFileName('');
            // setCName('');
            setCMsg('');
            setSubject('');
          } else {
            const errorData = await response.json();
            if (errorData.error) {
              alert(`Error: ${errorData.error}`);
            } else {
              alert("Error uploading data.");
            }
          }
        } catch (error) {
          console.error("Error:", error);
          alert("Error uploading data.");
        } finally {
          setFile(null);
          setFileName('');
          setLoading(false);
          fileInputRef.current.value = '';
        }
      },
    });
  };

  const handleSingleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const userId = getSessionData('user')?.id;
    if (!userId) {
      alert('User ID is missing');
      setLoading(false);
      return;
    }

    if(!first_name) {
      alert('Please enter first name');
      setLoading(false);
      return;
    }
    if(!last_name) {
      alert('Please enter last name');
      setLoading(false);
      return;
    }
    if(!smtp_username) {
      alert('Please enter SMTP username');
      setLoading(false);
      return;
    }
    if(!smtp_password) {
      alert('Please enter SMTP Password');
      setLoading(false);
      return;
    }
    if(!smtp_host) {
      alert('Please enter SMTP host');
      setLoading(false);
      return;
    }
    if(!smtp_port) {
      alert('Please enter SMTP port');
      setLoading(false);
      return;
    }
    if(!daily_limit) {
      alert('Please enter warmup limit');
      setLoading(false);
      return;
    }
    if(!warmup_limit) {
      alert('Please enter warmup limit');
      setLoading(false);
      return;
    }

    const endpoint = 'single-email-campaign';
    try {
      const response = await fetch(`${apiUrl}/api/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: userId, 
          cName, 
          subject, 
          cMsg,
          first_name,
          last_name,
          smtp_username,
          smtp_password,
          smtp_host,
          smtp_port,
          daily_limit,
          warmup_enabled,
          warmup_limit
        }),
      });

      if (response.ok) {
        alert("CSV data uploaded successfully!");
        setCMsg('');
        setSubject('');
        setFirstname('');
        setLastname('');
        setSMTPusername('');
        setSMTPpassword('');
        setSMTPhost('');
        setSMTPport('');
        setDailylimit('');
        setWarmupenabled(false);
        setWarmuplimit('');
      } else {
        const errorData = await response.json();
        if (errorData.error) {
          alert(`Error: ${errorData.error}`);
        } else {
          alert("Error add email account.");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error add email account.");
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <div className="tophead_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="title_mainsec">
              <h2>Add Email</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="addnew_emails_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
            <div className="csv_upload_boxsec">
              <form onSubmit={handleSubmit}>
                <div className="campaign_input_box">
                  <lable>Campaign Name</lable>
                  <input
                    type="text"
                    name="campaign_name"
                    className="form-control"
                    onChange={handleCampaignNameChange}
                    value={cName}
                  />
                  <br/>
                </div>

                <div className="campaign_input_box">
                  <lable>Subject</lable>
                  <input
                    type="text"
                    name="subject"
                    className="form-control"
                    onChange={(e) => setSubject(e.target.value)}
                    value={subject}
                  />
                  <br/>
                </div>

                <div className="campaign_input_box">
                  <lable>Campaign Message</lable>
                  <textarea
                    type="text"
                    name="campaign_msg"
                    className="form-control"
                    onChange={(e) => setCMsg(e.target.value)}
                    value={cMsg}
                  ></textarea>
                  <br/>
                </div>

                <div className="upload_box">
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleFileChange}
                    accept=".csv"
                    ref={fileInputRef}
                  />
                  <div className="upload_txt">
                    <i className="fas fa-upload"></i>
                    <div className="contnt_txt">
                      <p>{fileName ? fileName : 'Choose a file or drag it here.'}</p>
                    </div>
                  </div>
                </div>

                {loading && (
                  <div className="loader">
                    <p>Loading...</p>
                  </div>
                )}

                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="btn_mainsec">
                    <button type="submit" className="btn_style" disabled={loading}>
                      {loading ? "Uploading..." : "Submit"}
                    </button>
                    <a href="https://staging.automate-emailscale.io/sample_emails.csv" className="btn_style download_s_btn" download>Download Sample File</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
            <div className="or_textsec"><h3>OR</h3></div>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
            <form onSubmit={handleSingleSubmit}>
                <div className="campaign_input_box">
                  <lable>Campaign Name</lable>
                  <input
                    type="text"
                    name="campaign_name"
                    className="form-control"
                    onChange={handleCampaignNameChange}
                    value={cName}
                  />
                  <br/>
                </div>

                <div className="campaign_input_box">
                  <lable>Subject</lable>
                  <input
                    type="text"
                    name="subject"
                    className="form-control"
                    onChange={(e) => setSubject(e.target.value)}
                    value={subject}
                  />
                  <br/>
                </div>

                <div className="campaign_input_box">
                  <lable>Campaign Message</lable>
                  <textarea
                    type="text"
                    name="campaign_msg"
                    className="form-control"
                    onChange={(e) => setCMsg(e.target.value)}
                    value={cMsg}
                  ></textarea>
                  <br/>
                </div>
                <div className="campaign_input_box">
                  <lable>First Name</lable>
                  <input
                    type="text"
                    name="first_name"
                    className="form-control"
                    onChange={(e) => setFirstname(e.target.value)}
                    value={first_name}
                  />
                  <br/>
                </div>
                <div className="campaign_input_box">
                  <lable>Last Name</lable>
                  <input
                    type="text"
                    name="last_name"
                    className="form-control"
                    onChange={(e) => setLastname(e.target.value)}
                    value={last_name}
                  />
                  <br/>
                </div>
                <div className="campaign_input_box">
                  <lable>SMTP User Name</lable>
                  <input
                    type="text"
                    name="smtp_username"
                    className="form-control"
                    onChange={(e) => setSMTPusername(e.target.value)}
                    value={smtp_username}
                  />
                  <br/>
                </div>
                <div className="campaign_input_box">
                  <lable>SMTP Password</lable>
                  <input
                    type="text"
                    name="smtp_password"
                    className="form-control"
                    onChange={(e) => setSMTPpassword(e.target.value)}
                    value={smtp_password}
                  />
                  <br/>
                </div>
                <div className="campaign_input_box">
                  <lable>SMTP Host</lable>
                  <input
                    type="text"
                    name="smtp_host"
                    className="form-control"
                    onChange={(e) => setSMTPhost(e.target.value)}
                    value={smtp_host}
                  />
                  <br/>
                </div>
                <div className="campaign_input_box">
                  <lable>SMTP Port</lable>
                  <input
                    type="text"
                    name="smtp_port"
                    className="form-control"
                    onChange={(e) => setSMTPport(e.target.value)}
                    value={smtp_port}
                  />
                  <br/>
                </div>
                <div className="campaign_input_box">
                  <lable>Daily Limit</lable>
                  <input
                    type="text"
                    name="daily_limit"
                    className="form-control"
                    onChange={(e) => setDailylimit(e.target.value)}
                    value={daily_limit}
                  />
                  <br/>
                </div>
                <div className="campaign_input_box">
                  <label class="custom_checkbox">
                    <span className="label_txt">Warmup Enabled</span>
                    <input
                      type="checkbox"
                      name="warmup_enabled"
                      className=""
                      onChange={handleWarmupenabled}
                      value="TRUE"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <br/>
                </div>
                <div className="campaign_input_box">
                  <lable>Warmup Limit</lable>
                  <input
                    type="text"
                    name="warmup_limit"
                    className="form-control"
                    onChange={(e) => setWarmuplimit(e.target.value)}
                    value={warmup_limit}
                  />
                  <br/>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="btn_mainsec">
                    <button type="submit" className="btn_style" disabled={loading}>
                      {loading ? "Uploading..." : "Submit"}
                    </button>
                  </div>
                </div>
            </form>
          </div>
          {loading ? <div className="loader_mainsec">
            <div className="spinner"></div>
          </div> : <div></div>}
        </div>
      </div>
    </>
  );
};

export default AddEmail;

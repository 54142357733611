import React, { useState, useEffect } from 'react';
import { getSessionData } from './utils'; 

const apiUrl = process.env.REACT_APP_API_URL; 
const user = getSessionData('user'); 

const Analytics = () => {
  const [loading, setLoading] = useState(true); 
  const [analyticsdata, setAnalyticsdata] = useState([]);

    useEffect(() => {
    const fetchAnalyticsdata = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/get_analytics_data`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({ id: user.id }),
        });

        const result = await response.json();
        if (response.ok) {
          setAnalyticsdata(result);
        }
      } catch (error) {
        console.error("Error checking campaign name:", error);
      } finally {
        setLoading(false); 
      }
    };

    if (user && user.id) { 
      fetchAnalyticsdata();
    } else {
      setLoading(false); // Turn off loading indicator
    }
  }, []); 


  return (
    <div>
      <div className="tophead_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="title_mainsec">
              <h2>Analytics</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="campaign_chart">
        <div className="number_boxsec">
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <div className="innerbox">
                <h2>{analyticsdata ? analyticsdata.campaigns : 0}</h2>
                <h3>Total Campaigns</h3>
              </div>
            </div>
            
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <div className="innerbox">
                <h2>{analyticsdata ? analyticsdata.emails : 0}</h2>
                <h3>Total Emails</h3>
              </div>
            </div>
            
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <div className="innerbox">
                <h2>{analyticsdata ? analyticsdata.todayrunCampaign : 0}</h2>
                <h3>Today Run Campaigns</h3>
              </div>
            </div>
            
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <div className="innerbox">
                <h2>{analyticsdata ? analyticsdata.todayRemainingCampaign : 0}</h2>
                <h3>Today Remaining Campaigns</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loader_mainsec">
          <div className="loader_innerbox">
            <div className="spinner_box">
              <div className="spinner"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
